import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from './colors';
import { lighten } from 'polished';

export const outer = css`
  position: relative;
  padding: 0 4vw;
`;

// Centered content container blocks
export const inner = css`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`;

export const SiteMain = css`
  z-index: 100;
  flex-grow: 1;
`;

export const SiteTitle = styled.h1`
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 3.8rem;
  text-transform: capitalize;

  @media (max-width: 600px) {
    font-size: 2rem;
  }

  animation: welcome 2.75s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 1.2, 1, 1.2);

  img {
    margin-bottom: 1rem;
  }

  @keyframes welcome {
    0% {
      opacity: 0.2;
      transform: rotate(-2deg);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
`;

export const PostFeed = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
  @media (max-width: 600px) {
    padding: 15px 0 0 0;
  }
`;

export const PostFeedRaise = css`
  @media (min-width: 900px) {
    // margin-top: -70px;
    padding-top: 0;
  }
`;

export const SiteDescription = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.2rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`;

export const SocialLink = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: #fff;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }

  svg {
    height: 1.8rem;
    fill: ${colors.alpha};
  }
`;

export const SiteHeader = css`
  position: relative;
  padding-top: 22px;
  padding-bottom: 24px;
`;

export const SiteHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11vw 4vw;

  .logo {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
  }

  min-height: 200px;
  max-height: 450px;
  text-align: center;

  @media (max-width: 600px) {
    min-height: 140px;
    padding: 5vw 2vw;
    .logo {
      width: 75px;
      height: 75px;
    }
  }
`;

export const SiteHeaderStyles = css`
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 10;
  display: block;
  height: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }
  @media (max-width: 700px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const AuthorProfileImage = css`
  display: block;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: #333;
  border-radius: 100%;
  // object-fit: cover;
  margin-right: 15px;
  width: 60px;
  height: 60px;
`;
