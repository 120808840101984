import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'fannypack';
import { withPrefix } from 'gatsby';
import { darken, lighten } from 'polished';
import Helmet from 'react-helmet';
import config from '../website-config';

import * as React from 'react';

import { colors } from '../styles/colors';

const theme = {
  global: {
    fontFamily: 'Barlow',
    fontSize: '1.5rem',
  },
  palette: {
    primary: '#32BA55',
  },
};

// tslint:disable-next-line:no-unused-expression
const GlobalStyles = css` 
  @font-face {
    font-family: Barlow;
    src: url('${withPrefix("/fonts/Barlow-Regular.woff2")}') format('woff2'),
      url('${withPrefix("/fonts/Barlow-Regular.woff")}') format('woff');
    font-display: fallback;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  button {
    color: ${colors.omega};
  }
  
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  img {
    max-width: 100%;
  }
  html {
    box-sizing: border-box;
    font-family: sans-serif;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  a {
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }

  strong,
  span.under {
    font-weight: bold;
  }

  em,
  span.char {
    font-style: italic;
    text-decoration: ${colors.whitegrey} underline;
  }
  h1 {
    margin: 0.67em 0;
    font-size: 2em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  mark {
    background-color: #333;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font: inherit;
  }
  button {
    overflow: visible;
    border: none;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    cursor: pointer;

    -webkit-appearance: button;
  }
  button[disabled],
  html input[disabled] {
    cursor: default;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  input {
    line-height: normal;
  }
  input:focus {
    outline: none;
  }
  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  input[type='search'] {
    box-sizing: content-box;

    -webkit-appearance: textfield;
  }
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  legend {
    padding: 0;
    border: 0;
  }
  textarea {
    overflow: auto;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  td,
  th {
    padding: 0;
  }

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  

  ::selection {
    text-shadow: none;
    /* background: color(var(--blue) lightness(+30%)); */
    background: ${lighten('0.3', colors.alpha)};
  }

  hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 2.5em 0 3.5em;
    padding: 0;
    height: 1px;
    border: 0;
    /* border-top: 1px solid color(var(--lightgrey) l(+10%)); */
    border-top: 2px solid ${colors.whitegrey};
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  textarea {
    resize: vertical;
  }

  p,
  ul,
  ol,
  dl,
  blockquote {
    margin: 0 0 1.5em 0;
  }

  ol,
  ul {
    padding-left: 1.3em;
    padding-right: 1.5em;
  }

  ol ol,
  ul ul,
  ul ol,
  ol ul {
    margin: 0.5em 0 1em;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    max-width: 100%;
  }

  li {
    margin: 0.5em 0;
    padding-left: 0.3em;
    line-height: 1.6em;
  }

  dt {
    float: left;
    margin: 0 20px 0 0;
    width: 120px;
    color: ${colors.omega};
    font-weight: 500;
    text-align: right;
  }

  dd {
    margin: 0 0 5px 0;
    text-align: left;
  }

  blockquote {
    margin: 1.5em 0;
    padding: 0 1.6em 0 1.6em;
    border-left: ${colors.whitegrey} 0.5em solid;
  }

  .b3 {
    margin-bottom: 3rem;
  }
  .t3 {
    margin-top: 3rem;
  }

  blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    font-weight: 300;
  }

  blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    opacity: 0.8;
  }

  blockquote small:before {
    content: '\\2014 \\00A0';
  }

  blockquote cite {
    font-weight: bold;
  }
  blockquote cite a {
    font-weight: normal;
  }

  a {
    /* color: color(var(--blue) l(-5%)); */
    color: ${colors.alpha};
  }

  a:hover {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    line-height: 1.15;
    font-weight: 700;
    text-rendering: optimizeLegibility;
  }

  h1 {
    margin: 0 0 0.5em 0;
    font-size: 5rem;
    font-weight: 700;
    color: ${colors.omega};
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 2.2rem;
    }
  }

  h2 {
    margin: 1.5em 0 0.5em 0;
    font-size: 2rem;
  }
  @media (max-width: 500px) {
    h2 {
      font-size: 1.8rem;
    }
  }

  h3 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.8rem;
    font-weight: 500;
  }
  @media (max-width: 500px) {
    h3 {
      font-size: 1.7rem;
    }
  }

  h4 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.6rem;
    font-weight: 500;
  }

  h5 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
  }

  h6 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
  }

  body {
    background: #f8f8f8;
    border-top: 4px solid #32BA55;
  }

  span.key-pc,
  span.key-mac,
  span.key {
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05) inset, 1px 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 0.6em;
    padding: 4px 7px;
    white-space: nowrap;
    border-bottom: 1px solid ${colors.whitegrey};
    border-right: 1px solid ${colors.whitegrey};
  }
  span.key-mac::before {
    content: 'MAC: ';
    font-size: 0.6em;
    color: ${colors.alpha};
    vertical-align: middle;
  }
  span.key-pc::before {
    content: 'PC: ';
    font-size: 0.6em;
    color: ${colors.alpha};
    vertical-align: middle;
  }
  .adjustment,
  .blending,
  .tool {
    border-radius: 5px;
    color: ${colors.omega};
    padding: 2px 6px;
    white-space: nowrap;
    background: ${lighten(0.03, colors.whitegrey)};
  }
  .text-center {
    text-align: center;
  }
  .b1 {
    margin-bottom: 1rem;
  }
  .b2 {
    margin-bottom: 2rem;
  }
  .b3 {
    margin-bottom: 3rem;
  }
  .t1 {
    margin-top: 1rem;
  }
  .t2 {
    margin-top: 2rem;
  }
  .t3 {
    margin-top: 3rem;
  }
  figcaption {
    margin-bottom: 3.5rem;
    font-size: 2rem;
   // text-decoration: ${colors.beta} solid underline;
  }
  figcaption a {
    box-shadow: none !important;
  }
  section.info-box {
    background: ${lighten('0.04', colors.whitegrey)};
    border-left: ${colors.whitegrey} 5px solid;

    text-align: justify;
    line-height: 1.4;
    padding: 1.5rem 2rem;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  section.info-box.v2 {
    background: ${lighten('0.02', colors.whitegrey)};
    border-left: ${colors.beta} 5px solid;
  }

  /* VS Posts */

  .compare-panes {
    position: relative;
  }
  .product-names,
  .feature {
    display: grid;
    grid-template-columns: 0.75fr 1fr 1fr;
    min-height: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .alt {
    grid-template-columns: 0.75fr 1fr;
  }
  .alt ul {
    margin-bottom: 0;
    text-align: left;
  }
 
  .product-names {
    z-index: 1;
    background: white;
    font-size: 3rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-bottom: 2px  #D7F2DE;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.75rem;
    padding-bottom: 0.75rem;
  }
  .product-names img,
  .product-names .gatsby-resp-image-wrapper {
    width: 175px !important;
    height: auto !important;
  }
  .product-names,
  .feature-name {
    font-weight: bold;
  }
  .feature-name {
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.2;
  }
  .feature {
    border: 2px solid #D7F2DE;
    margin-bottom: 1rem;
    transition: transform 0.2s ease-out;
  }
  .feature:not(.purchase):hover {
    transform: scale(1.025);
  }
  .feature.different {
    background: #D7F2DE;
  }
  .compare-panes h3 {
    text-align: center;
    text-decoration: none !important;
    padding: 2rem;
    margin-top: 4rem !important;
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 25px,
      rgba(246, 244, 245, 0.64) 25px,
      rgba(246, 244, 245, 0.64) 50px
    );
  }
  .winner,
  .loser {
    /* border: 2px dashed #d6c5d9; */
    border: 2px solid white;
    box-shadow: 0 2px 10px rgba(53, 30, 72, 0.06);
    position: relative;
    border-radius: 8px;
  }
  .winner::before {
    content: '👍';
    width: 38px;
    height: 38px;
    font-size: 2.4rem;
    display: inline-flex;
    justify-content: center;
    position: absolute;
    left: -2rem;
    border-radius: 50%;
    align-items: center;
    top: -2rem;
    background: white;
  }
  .product-a {
    grid-column: 2;
  }
  #rating-note {
    text-align: right;
    margin-top: 2.4rem;
    font-size: 1.7rem;
    line-height: 1.4;
    max-width: 600px;
    margin-left: auto;
  }
  .purchase {
    border: none;
    border-top: 2px solid #D7F2DE;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    margin-top: 1.75rem;
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 25px,
      rgba(246, 244, 245, 0.4) 25px,
      rgba(246, 244, 245, 0.4) 50px
    );
  }
  .purchase span {
    color: #333;
  }
  figure.showcase {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(39, 44, 49, 0.1) 0px 0px 38px, rgba(39, 44, 49, 0.04) 0px 0px 8px;
    margin: 3rem 0;
  }
  h3.showcase span {
    background: #f2eaf8;
    padding: 1rem 2rem;
    transform: skewX(-20deg);
    display: inline-block;
    background: #D2EDD9;
    box-shadow: inset -2px -2px 0px #8AD69E;
    color: #333;
    font-size: 28px;
  }

  @media (max-width: 450px) {
    .product-names img,
    .product-names .gatsby-resp-image-wrapper {
      width: 45px !important;
    }
    .product-names,
    .feature-name {
      font-weight: bold;
      font-size: 1.3rem;
    }
    .feature-name {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      line-height: 1;
    }
    .winner::before {
      width: 28px;
      height: 28px;
      font-size: 1.8rem;
      left: -1.4rem;
      top: -1.4rem;
    }
    .compare-panes a,
    #rating-note a {
      box-shadow: none !important;
      text-decoration: rgba(155, 89, 182, 0.3) solid underline;
    }
    .purchase a {
      display: block;
    }
    .purchase span {
      display: none;
    }
  }
  body {
    overflow-x: hidden;
    color: ${colors.omega};
    font-family: Barlow, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
      Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: #f8f8f8;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga' on;
  }
`;

interface IndexProps {
  className?: string;
}

const IndexLayout: React.FunctionComponent<IndexProps> = (props) => {
  return (
    <>
      <Helmet>
        <meta name="msapplication-TileColor" content="#fabb00" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <div className={props.className}>
          <Global styles={GlobalStyles} />
          {props.children}
        </div>
      </ThemeProvider>
    </>
  );
};

export default IndexLayout;
