export const colors = {
  alpha: '#32BA55',
  beta: '#BDE5C7',
  omega: '#333',
  yellow: '#fecd35',
  red: '#f05230',
  midgrey: '#3D8C52',
  whitegrey: '#DAEFE0',

  pink: '#fa3a57',
  brown: '#a3821a',
};
