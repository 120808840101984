import { Link } from 'gatsby';
import * as React from 'react';
import { css } from '@emotion/core';

const SiteNavLogoStyles = css`
  flex-shrink: 0;
  display: block;
  margin-right: 24px;
  margin-left: 3px;
  line-height: 1em;
  transition: transform 0.2s cubic-bezier(0, 1.8, 1, 1.8);
`;

const SvgFullLogoStyles = css`
  @media (max-width: 800px) {
    display: none;
  }
`;

const SvgSmallLogoStyles = css`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

const SiteNavLogo = () => (
  <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/" title="Go to home page">
    <svg css={SvgFullLogoStyles} width="170" height="24" viewBox="0 0 905 128" fill="#32ba55" xmlns="http://www.w3.org/2000/svg">
      <path d="M66.4 128c34.5 0 60.7-22.9 60.7-60.5V57H65.4v24.6h32.8c-4.4 11.8-15.1 19-31.4 19-13.5 0-24-4.8-30.6-12.7-8-9.4-8-20.2-8-23.5 0-3.3 0-17.4 11.4-27.7 10-9 21.7-9.2 24.7-9.2 15.9 0 26 10.1 28 12H125a63.2 63.2 0 0 0-15.8-22.8A66.3 66.3 0 0 0 64.5 0c-2 0-35.9 1-54.1 29a64.1 64.1 0 0 0 56 99Zm198.8 0c34.5 0 60.6-22.9 60.6-60.5V57h-61.6v24.6H297c-4.4 11.8-15.1 19-31.5 19-13.4 0-23.9-4.8-30.6-12.7-7.9-9.4-8-20.2-8-23.5 0-3.3.1-17.4 11.5-27.7 9.9-9 21.7-9.2 24.7-9.2 15.9 0 26 10.1 27.9 12h32.8a63.2 63.2 0 0 0-15.7-22.8A66.3 66.3 0 0 0 263.3 0c-2 0-36 1-54.1 29a64.1 64.1 0 0 0 56 99Zm206.5-60.5c0 37.6-26.1 60.5-60.6 60.5a64.1 64.1 0 0 1-56-99c18.2-28 52-29 54-29 5.6 0 27.1.3 45 16.6 9 8.3 13.5 17.4 15.7 22.9h-32.9c-2-2-12-12.1-27.9-12.1-3 0-14.8.2-24.7 9.2a37.5 37.5 0 0 0-11.5 27.7c0 3.3.2 14 8 23.5a38.7 38.7 0 0 0 30.7 12.7c16.3 0 27-7.2 31.4-19h-32.8V57h61.6v10.6Zm-294.7 58V2.5h-28.1v123h28Zm508.2-96h-31.6v96h-28.1v-96h-31.7v-27h91.4v27Zm100.1 96v-27h-49.2V76.6h44V50h-44V29.5h48.3v-27H708v123h77.3Zm66.3-40.4h-13.4v40.4h-28V2.5h49.1a42.1 42.1 0 0 1 42.2 42.2c0 15.1-9.2 28.8-22.7 35.6l26.2 45.2h-30.3l-23.1-40.4Zm-15-24.6h21.1c7.8 0 14.1-6.9 14.1-15.9s-6.3-15.8-14-15.8h-21.2v31.7ZM488 88.7c0 21.7 20 39.3 46.2 39.3 34.5 0 45.7-21.3 45.7-36.7 0-26.8-20.1-33.1-37.4-38.5l-4.6-1.5c-10.5-3.4-20-6.6-19.7-15.1.1-2.3 2-8.8 13.6-8.8 14.9 0 17.5 11.5 17.5 12h27.9c0-15.2-13.8-39.4-45.4-39.4C504.5 0 490 18.3 490 36.4c0 11.6 3.5 19.5 9.3 25.1 8 8 21.1 11.7 27.7 13.6l1.6.5 3.2.9c8.3 2.2 20 5.4 20 13.7 0 8.4-7.7 10.4-17 10.4-12.5 0-18.8-7.6-18.8-12h-28Z"/>
    </svg>
    <svg css={SvgSmallLogoStyles} width="32" height="32" viewBox="0 0 128 128" fill="#32ba55" xmlns="http://www.w3.org/2000/svg">
      <path d="M125.8 47.31a64.01 64.01 0 0 0-9.82-20.66 149.1 149.1 0 0 0-53.83 30.8c-4.77-3.6-9.67-7.02-14.7-10.24a169.15 169.15 0 0 0-15.69 16.85 212.24 212.24 0 0 1 32.07 24.19 127.32 127.32 0 0 1 40.7-32.52A41.41 41.41 0 0 1 64 105.36C41.36 105.57 22.43 86.4 22.64 64a41.41 41.41 0 0 1 54.72-39.15c7.97-4.67 16.43-8.71 25.11-12A63.85 63.85 0 0 0 64 0a63.9 63.9 0 0 0-45.25 18.75A63.9 63.9 0 0 0 0 64a63.9 63.9 0 0 0 18.75 45.25A63.9 63.9 0 0 0 64 128a63.88 63.88 0 0 0 45.25-18.75A63.88 63.88 0 0 0 128 64c0-5.7-.74-11.28-2.2-16.69Z" />
    </svg>
  </Link>
);

export default SiteNavLogo;
